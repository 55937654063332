export interface IActivityCreateData {
  name: string;
  startingTime: string;
  type: string;
  endingTime: string;
  startingDate?: string;
  endingDate?: string;
  location?: string;
  description?: string;
  image?: any;
  posterImageId?: string;
  repeatable?: boolean;
  weekdaysList?: string[];
  particularDates?: string[];
  repeatOption?: string;
  updateForSpecifiedDate?: string;
}
export interface IFacilityAddress {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
}
export interface IFacilityDetails {
  facilityName: string;
  id: string;
  email: string;
  facilityAddress: IFacilityAddress;
}
export interface IActivity {
  id: string;
  name: string;
  startingTime: string;
  startingDate?: string;
  endingTime: string;
  endingDate?: string;
  description: string;
  type: string;
  location: string;
  facility: IFacilityDetails;
  noOfGuestsInterested: number;
  posterImageId?: string;
  repeatable?: boolean;
  weekdaysList?: string[];
  particularDates?: string[];
}
export interface IDeleteActivityPayload {
  id: string;
  facilityId: string;
  date: string;
  repeatOption: string;
}
export interface IRemoveRepeatingActivityPayload {
  id: string;
  facilityId: string;
  date: string;
  repeatOption: string;
}
export interface IActivityType {
  notification: string;
  event: string;
}
export const ActivityType: IActivityType = {
  notification: 'NOTIFICATION',
  event: 'EVENT',
};
export interface IActivitiesFilterPayload {
  facilityIds?: string;
  sort: string;
  page: number;
  size: number;
  date?: string;
}
export interface IActivitiesFilter {
  sort: string;
  page: number;
  size: number;
}
export interface IDeleteEditActivityPayload {
  id: string;
  for: string;
}
export interface IActivityPaginate {
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
  size: number;
  number: number;
}
export interface IActivityValidation {
  name: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  location: string;
  description: string;
}
export interface IActivityData {
  id?: string;
  name: string;
  startingTime: string;
  endingTime: string;
  type: string;
  facilityId: string;
  endingDate?: string;
  startingDate?: string;
  location?: string;
  description?: string;
  image?: any;
  posterImageId?: string;
  repeatable?: boolean;
  weekdaysList?: string[];
  particularDates?: string[];
  date?: string;
  repeatOption?: string;
  updateForSpecifiedDate?: string;
}

export interface IGuestUserData {
  id: string;
  userName: string;
  email: string;
  photoId: string;
}

export interface IActivityRepeatTypes {
  type: string;
  name: string;
}
export const activityRepeatTypes: IActivityRepeatTypes[] = [
  {
    type: 'doesNotRepeat',
    name: 'Does Not Repeat',
  },
  {
    type: 'repeat',
    name: 'Repeat',
  },
  {
    type: 'particularDays',
    name: 'Particular Days',
  },
];
export interface IActivityOrderPayload {
  newPosition: IActivityOrderProps[];
  // date: string;
}
export interface IActivityOrderProps {
  activityId: string;
  position: number;
}
