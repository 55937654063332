import React from 'react';
import { convertDiningTime } from '../../pages/diningPage/utilityFunction';

const TimeComponent: React.FC<{
  startTime: string;
  endTime: string;
}> = ({ startTime, endTime }) => {
  return (
    <div className="restaurant-time">
      <span>{convertDiningTime(startTime)}</span>
      <span>&nbsp;-&nbsp;</span>
      <span>{convertDiningTime(endTime)}</span>
    </div>
  );
};

export default TimeComponent;
