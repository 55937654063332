import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Modal, Spinner, UncontrolledAccordion } from 'reactstrap';
import 'swiper/swiper-bundle.css';
import { activeInactiveChecker } from '../../_helpers/checkerFunctions';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { CalenderComp } from '../../components/calenderComp/calenderComp';
import { diningBreakPoints } from '../../components/calenderComp/swiperBreakPoints';
import DiningAccordion from '../../components/diningAccordion/accordion';
import MealCard from '../../components/diningCards/mealCard';
import { IDropdownList } from '../../components/diningDropdown/dropdownComp';
import AddEditCategory from '../../components/diningModal/AddCategoryModal';
import AddEditDailyMeal from '../../components/diningModal/AddEditDailyMeal';
import ListCategory from '../../components/diningModal/ListCategory';
import AddEditRepeatMeal from '../../components/diningModal/addEditRepeatMeal';
import AddNewRepeat from '../../components/diningModal/addNewRepeat';
import FullScreenImgComp from '../../components/fullScreenImage/fullScreenImgComp';
import RestaurantImgComp from '../../components/imageComponents/restaurantImgComp';
import { DropDownComponent } from '../../components/menuDropDown/dropDownComponent';
import { DiningMealModal } from '../../components/modal/diningMealModal';
import { DiningModalWrap } from '../../components/modal/diningModalWrap';
import {
  openCategoryAddModalReducer,
  openCategoryListModalReducer,
  openDiningRepeatAddModalReducer,
} from '../../components/modal/modalSlice';
import { IPaginateClickData } from '../../components/paginate/relativePagination';
import { PdfComponent } from '../../components/pdfViewer/pdfComponent';
import TimeCompWrap from '../../components/restaurants/timeCompWrap';
import SectionLoader from '../../components/sectionLoader/sectionLoader';
import '../amenitiesPage/amenitiesPage.css';
import { getSelectedFacility } from '../loginPage/authSlice';
import {
  IAddNewDiningMealsPayload,
  IMealItems,
  IMealsPerDay,
  IMenuFileSrc,
  IRestaurants,
  IUpdateDailyDiningMealsPayload,
  IUpdateRepeatDiningMealsPayload,
  daysArray,
} from './diningInterface';
import './diningPage.css';
import {
  addFilterCategoryList,
  addFilterMealsPage,
  addNewDiningMeals,
  addToScheduleAction,
  changeDiningOrder,
  clearDeleteArrayReducer,
  createCategory,
  deleteMealsAction,
  fetchDailyMealItemsUsingMealId,
  fetchDiningMealUsingDate,
  fetchMealItemsUsingMealId,
  fetchRepeatMealItemsUsingMealId,
  fetchRestaurantById,
  getAllCategoryById,
  removeMultipleRepeatMealsData,
  resetDiningState,
  skipDailymealsApi,
  updateCategory,
  updateDailyMealItems,
  updateRepeatMealItems,
} from './diningSlice';

export interface IDayData {
  name: string;
  startTime: string;
  endTime: string;
  items: string;
}
export interface IRepeatingList {
  day: string;
  dayData: IDayData[];
}
export interface IActiveObject {
  isCurrentDate: boolean;
  isSelected: boolean;
}

const DiningPage: React.FC = () => {
  const { f_id, restaurant_id } = useParams();
  const { currentFacility } = useAppSelector((state) => state.authentication);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [activeId, setActiveId] = useState<any>(null);
  const [items, setItems] = useState<IMealsPerDay[]>([]);
  const [activeData, setActiveData] = useState<IMealsPerDay>(
    {} as IMealsPerDay
  );
  const {
    mealsPerDay,
    isAddingDiningMeal,
    isFetchingMealsUsingDate,
    isUpdatingRepeatMealItems,
    isUpdatingDailyMealItems,
    isAddingToSchedule,
    categoryRefreshTrigger,
    categories,
    mealsPerDayPaginate,
    mealsFilter,
    categoryFilter,
  } = useAppSelector((state) => state.dining);

  const {
    openDiningRepeatModal,
    openDiningDailyAddEditModal,
    openDiningRepeatAddEditModal,
    openMealDataModal,
    openDiningCategoryModal,
    openDiningCategoryListModal,
  } = useAppSelector((state) => state.modal);
  const [mySwiper, setMyswiper] = useState<any>();
  const [dropdown, setDropdown] = useState<string>('');
  const [date, setDate] = useState(moment());
  const [modalMealData, setModalMealData] = useState<IMealsPerDay>(
    {} as IMealsPerDay
  );
  const [mealItems, setMealItems] = useState<IMealItems[]>([]);
  const [fetch, setFetch] = useState<boolean>(false);

  const [dailyMealData, setDailyMealData] = useState<IMealItems[]>([]);
  const [isDailyMealData, setIsDailyMealData] = useState<boolean>(false);
  const [pdfComponentData, setPdfComponentData] = useState<IMenuFileSrc>();
  const [ispdfComponent, setIsPdfComponent] = useState<boolean>(false);
  const [translation] = useTranslation('common');

  const [singleImageComponent, setSingleImageComponent] =
    useState<IMenuFileSrc>();
  const [isSingleImageComponent, setIsSingleImageComponent] =
    useState<boolean>(false);

  const [repeatMealData, setRepeatMealData] = useState<IMealItems[]>([]);
  const [isRepeatMealData, setIsRepeatMealData] = useState<boolean>(false);

  const [restaurant, setRestaurant] = useState<IRestaurants>(
    {} as IRestaurants
  );
  const [mealObject, setMealObject] = useState<IMealsPerDay>(
    {} as IMealsPerDay
  );
  const [accordionNoData, setAccordionNoData] = useState<boolean>(false);
  const [viewMenuDropDown, setviewMenuDropDown] = useState<boolean>(false);

  const [isMenuSwiper, setIsMenuSwiper] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (f_id) {
      dispatch(getSelectedFacility(f_id));
    }
    return () => {
      dispatch(resetDiningState());
    };
  }, []);
  useEffect(() => {
    if (restaurant_id) {
      dispatch(getAllCategoryById(restaurant_id));
    }
  }, [categoryRefreshTrigger, categoryFilter]);
  useEffect(() => {
    if (openDiningDailyAddEditModal == false) {
      dispatch(clearDeleteArrayReducer());
    }
  }, [openDiningDailyAddEditModal]);

  const addNewDiningList = () => {
    dispatch(openDiningRepeatAddModalReducer());
  };
  const addNewCategory = () => {
    dispatch(openCategoryAddModalReducer());
  };
  const viewCategories = () => {
    dispatch(openCategoryListModalReducer());
    dispatch(addFilterCategoryList(0));
  };
  const showOptions = (key: string) => {
    setDropdown(dropdown == key ? '' : key);
  };

  useEffect(() => {
    const getRestaurantFunc = async () => {
      if (restaurant_id) {
        const response = await dispatch(
          fetchRestaurantById({ id: restaurant_id })
        );
        setRestaurant(response.payload?.data[0]);
      }
    };
    getRestaurantFunc();
  }, [restaurant_id]);

  useEffect(() => {
    const getMealItemsFunc = async () => {
      if (modalMealData?.id) {
        const response = await dispatch(
          fetchMealItemsUsingMealId(modalMealData?.id)
        );
        setMealItems(response.payload?.data[0]?.data);
        setFetch(false);
      }
    };
    getMealItemsFunc();
  }, [fetch]);

  useEffect(() => {
    const getDailyMealItemsFunc = async () => {
      if (mealObject?.id) {
        const response = await dispatch(
          fetchDailyMealItemsUsingMealId(mealObject?.id)
        );
        setDailyMealData(response.payload?.data[0]?.data);
        setIsDailyMealData(false);
      }
    };
    getDailyMealItemsFunc();
  }, [isDailyMealData]);

  useEffect(() => {
    const getRepeatMealItemsFunc = async () => {
      if (mealObject?.id) {
        const response = await dispatch(
          fetchRepeatMealItemsUsingMealId(mealObject?.id)
        );
        setRepeatMealData(response.payload?.data[0]?.data);
        setIsRepeatMealData(false);
      }
    };
    getRepeatMealItemsFunc();
  }, [isRepeatMealData]);

  useEffect(() => {
    if (f_id && restaurant_id) {
      if (mealsFilter?.page === 0) {
        setItems([]);
      }
      dispatch(
        fetchDiningMealUsingDate({
          facilityIds: f_id,
          restaurantId: restaurant_id,
          date: date.format('YYYY-MM-DD'),
        })
      );
    }
  }, [date, mealsFilter]);
  // useEffect(() => {
  //   setItems(mealsPerDay);
  // }, [mealsPerDay]);
  const makeArrayUnique = (inputArray: IMealsPerDay[]) => {
    const uniqueArray: IMealsPerDay[] = [];
    const idSet = new Set();

    inputArray.forEach((item) => {
      if (!idSet.has(item?.id)) {
        idSet.add(item?.id);
        uniqueArray.push(item);
      }
    });

    return uniqueArray;
  };
  const addNewRequestData = (activity: IMealsPerDay[]) => {
    const updatedRequestArray = [...items, ...activity];

    setItems(makeArrayUnique(updatedRequestArray));
  };
  useEffect(() => {
    addNewRequestData(mealsPerDay as IMealsPerDay[]);
  }, [mealsPerDay]);
  const onCardClick = (data: IMealsPerDay) => {
    setModalMealData(data);
    setFetch(true);
  };

  const addNewDiningMealsHandler = (
    item: IAddNewDiningMealsPayload,
    date: string
  ) => {
    dispatch(addNewDiningMeals({ item, date }));
  };

  const addEditModalHandler = (data: IMealsPerDay, keys: IDropdownList) => {
    if (keys.tag == 'Add/Edit List' && keys.type == 'DAILY') {
      setMealObject(data);
      setIsDailyMealData(true);
    } else if (keys.tag == 'Add/Edit List' && keys.type == 'REPEAT') {
      setMealObject(data);
      setIsRepeatMealData(true);
    } else if (keys.tag == 'Add to Schedule' && keys.type == 'REPEAT') {
      dispatch(
        addToScheduleAction({
          item: {
            date: date.format('YYYY-MM-DD'),
            title: data.mealType,
            startTime: data.startTime,
            endTime: data.endTime,
            restaurantId: data.restaurantId,
          },
          id: data.id,
        })
      );
    } else if (keys.tag == 'Remove' && keys.type == 'DAILY') {
      if (data?.date) {
        dispatch(
          deleteMealsAction({
            id: data.id,
            date: date.format('YYYY-MM-DD'),
            restaurantId: data.restaurantId,
            weeks: data.weekdays,
          })
        );
      } else {
        dispatch(
          skipDailymealsApi({
            id: data.id,
            skipDate: date.format('YYYY-MM-DD'),
            date: date.format('YYYY-MM-DD'),
            weeks: data.weekdays,
            restaurantId: data.restaurantId,
          })
        );
      }
    }
  };

  const removeRepeatHandler = (
    data: IMealsPerDay,
    keys: IDropdownList,
    day: string
  ) => {
    if (keys.tag == 'Remove' && keys.type == 'REPEAT') {
      if (data?.weekdays.length === 1) {
        dispatch(
          deleteMealsAction({
            id: data.id,
            date: date.format('YYYY-MM-DD'),
            restaurantId: data.restaurantId,
            weeks: data.weekdays,
          })
        );
      } else if (data?.weekdays.length > 1 && day) {
        const weekArray = [...data.weekdays];
        const modifiedWeekArray = weekArray?.filter(
          (days: string) => days != day
        );

        if (modifiedWeekArray.length) {
          dispatch(
            removeMultipleRepeatMealsData({
              weekdaysList: [...modifiedWeekArray],
              id: data.id,
              restaurantId: data.restaurantId,
              repeatable: true,
            })
          );
        }
      }
    }
  };

  const updateDailyDiningMealsHandler = (
    data: IUpdateDailyDiningMealsPayload
  ) => {
    dispatch(updateDailyMealItems(data));
  };
  const updateRepeatDiningMealsHandler = (
    item: IUpdateRepeatDiningMealsPayload,
    date: string
  ) => {
    dispatch(updateRepeatMealItems({ item, date }));
  };
  const createNewCategory = (data: string) => {
    dispatch(
      createCategory({
        restaurantId: restaurant_id as string,
        categoryName: data,
      })
    );
  };
  const onUpdateCategory = (data: { id: number; categoryName: string }) => {
    dispatch(
      updateCategory({
        id: data.id as number,
        categoryName: data.categoryName,
      })
    );
  };

  const box = document.querySelector('.accordionItem') as HTMLDivElement | null;
  const rect = box?.getBoundingClientRect();
  useEffect(() => {
    if (!rect) {
      setAccordionNoData(true);
    } else {
      setAccordionNoData(false);
    }
  }, [rect]);

  const closeEnlargeImage = () => {
    // setIsMenuSwiper(false);
    setIsSingleImageComponent(false);
  };
  const closePdfViewer = () => {
    setIsMenuSwiper(false);
    setIsPdfComponent(false);
    setviewMenuDropDown(!viewMenuDropDown);
  };
  const viewMenu = () => {
    setviewMenuDropDown(!viewMenuDropDown);
    setIsMenuSwiper(true);
  };

  const closeDropDown = () => {
    setDropdown('');
    setviewMenuDropDown(false);
  };
  const onMenuFileItemSelected = (data: IMenuFileSrc) => {
    if (data.fileType === 'application/pdf') {
      setPdfComponentData(data);
      setIsPdfComponent(true);
    } else if (data.fileType !== 'application/pdf') {
      setSingleImageComponent(data);
      setIsSingleImageComponent(true);
    }
  };
  const handlePageClick = (data: IPaginateClickData) => {
    if (data.selected !== mealsPerDayPaginate.totalPages) {
      dispatch(addFilterMealsPage(data?.selected));
    }
  };
  const changePageFilterHandler = (page: number) => {
    if (page + 1 <= mealsPerDayPaginate.totalPages) {
      dispatch(addFilterMealsPage(page));
    }
  };
  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setActiveId(event.active.id);
      const activeObj = items.filter(
        (obj: IMealsPerDay) => obj?.id == event.active.id
      );

      setActiveData(activeObj[0]);
    },
    [items]
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = items.findIndex(
          (item: IMealsPerDay) => item.id == active?.id
        );

        const newIndex = items.findIndex(
          (item: IMealsPerDay) => item.id === over?.id
        );
        setItems((items: IMealsPerDay[]) => {
          return arrayMove(items, oldIndex, newIndex);
        });

        const newArray = arrayMove(items, oldIndex, newIndex).map(
          (items: IMealsPerDay, index: number) => ({
            mealId: items?.id,
            position: index + 1,
          })
        );

        dispatch(
          changeDiningOrder({
            date: date.format('YYYY-MM-DD'),
            newPosition: newArray,
          })
        );
      }

      setActiveId(null);
    },
    [items]
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);
  return (
    <>
      <div
        className={
          dropdown || viewMenuDropDown ? 'dingingDropdown-overlay' : ''
        }
        onClick={closeDropDown}
      ></div>
      {ispdfComponent && pdfComponentData?.file ? (
        <PdfComponent close={closePdfViewer} url={pdfComponentData?.file} />
      ) : (
        <div>
          {isSingleImageComponent && singleImageComponent?.file && (
            <FullScreenImgComp
              image={singleImageComponent?.file}
              state={isMenuSwiper}
              closeClass={closeEnlargeImage}
            >
              <img src={singleImageComponent?.file.toString()} alt="" />
            </FullScreenImgComp>
          )}
          <div
            className={dropdown && 'dingingDropdown-overlay'}
            onClick={() => setDropdown('')}
          ></div>
          <div className="page-background dining-page">
            <div className="amenitiesPage-head page-boundarie">
              {restaurant && (
                <div className="diningPage-heading-div">
                  <RestaurantImgComp
                    imageId={restaurant?.imageId}
                    className="restImage-diningPage"
                    divClass="restImage-div"
                    noImageClass="restaurant-noImage"
                    noImageDivClass="restImage-noImage-div"
                    src="/assets/emptyImage.svg"
                  />
                  <div className="diningPage-heading">
                    <h2>{restaurant?.restaurantName}</h2>
                    <div className="diningPage-menu-div">
                      {restaurant?.startTime && restaurant?.endTime ? (
                        <TimeCompWrap
                          startTime={restaurant?.startTime}
                          endTime={restaurant?.endTime}
                        />
                      ) : null}
                      {restaurant?.menuIds?.length ? (
                        <>
                          <div className="viewMenu-btn-separator"></div>
                          <div style={{ position: 'relative' }}>
                            <span
                              className="viewMenu-btn pointer"
                              onClick={viewMenu}
                            >
                              {translation('DINING_SCHEDULES.VIEW_MENU')}
                            </span>
                            {viewMenuDropDown && (
                              <DropDownComponent
                                onSelected={onMenuFileItemSelected}
                                menuFileList={restaurant?.menuIds}
                              />
                            )}
                          </div>
                        </>
                      ) : null}
                      {categories?.length ? (
                        <>
                          <div className="viewMenu-btn-separator"></div>
                          <div style={{ position: 'relative' }}>
                            <span
                              className="viewMenu-btn pointer"
                              onClick={viewCategories}
                            >
                              {translation('DINING_SCHEDULES.VIEW_CATEGORY')}
                            </span>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              <div className="dining-buttons-class">
                <Button
                  className="addAmenities-button shadow-none"
                  disabled={!activeInactiveChecker(currentFacility?.status)}
                  onClick={addNewCategory}
                >
                  {translation('DINING_SCHEDULES.ADD_CATEGORY')}
                </Button>
                <Button
                  className="addAmenities-button shadow-none"
                  disabled={!activeInactiveChecker(currentFacility?.status)}
                  onClick={addNewDiningList}
                >
                  {translation('DINING_SCHEDULES.ADD_NEW')}
                </Button>
              </div>
            </div>
            <div className="diningContent-div">
              <div className="calender-content-div">
                <CalenderComp
                  setDate={setDate}
                  setSwiperState={setMyswiper}
                  dayBackFn={() => mySwiper.slidePrev()}
                  dayForwardFn={() => mySwiper.slideNext()}
                  swiperBreakPoints={diningBreakPoints}
                />
                <div className="card-wrap page-boundarie">
                  <span className="card-wrap-heading">
                    {translation('DINING_SCHEDULES.TODAYS_DINING_SCHEDULE')}
                  </span>
                  {isFetchingMealsUsingDate || isAddingToSchedule ? (
                    <SectionLoader />
                  ) : mealsPerDay?.length ? (
                    <div className="dining-card dining-parent-card-R relative  ">
                      <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        onDragCancel={handleDragCancel}
                      >
                        <SortableContext
                          items={items}
                          strategy={rectSortingStrategy}
                        >
                          {items?.map((item: IMealsPerDay, index: number) => (
                            <MealCard
                              dragabble
                              key={`Daily-${index}`}
                              compKey={`Daily-${index}`}
                              className="perDay-mealCard"
                              showOptions={showOptions}
                              dropdown={dropdown}
                              perDayData={item}
                              onCardClick={onCardClick}
                              restaurantImg={restaurant?.imageId}
                              addEditModalHandler={addEditModalHandler}
                              removeRepeatHandler={removeRepeatHandler}
                            />
                          ))}
                        </SortableContext>
                        <DragOverlay
                          adjustScale
                          style={{ transformOrigin: '0 0 ' }}
                        >
                          {activeId ? (
                            <MealCard
                              dragabble
                              key={`Daily-${0}`}
                              compKey={`Daily-${0}`}
                              className="perDay-mealCard"
                              showOptions={showOptions}
                              dropdown={dropdown}
                              perDayData={activeData}
                              onCardClick={onCardClick}
                              restaurantImg={restaurant?.imageId}
                              addEditModalHandler={addEditModalHandler}
                              removeRepeatHandler={removeRepeatHandler}
                            />
                          ) : null}
                        </DragOverlay>
                      </DndContext>
                      {/* {mealsPerDayPaginate?.totalPages > 10 && (
                        <RelativePaginate
                          handlePageClick={handlePageClick}
                          totalPage={mealsPerDayPaginate?.totalPages}
                        />
                      )} */}
                    </div>
                  ) : (
                    <div className="no-dining-meals-div">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          '/assets/noDiningSchedule.svg'
                        }
                        alt=""
                      />
                      <span className="no-dining-meals-text">
                        {translation('DINING_SCHEDULES.SCHEDULE_NOT_ADDED')}
                      </span>
                    </div>
                  )}
                  {mealsFilter.page + 1 < mealsPerDayPaginate?.totalPages &&
                  items?.length != 0 ? (
                    <div className="pagination-down-arrow-container-activity">
                      {isFetchingMealsUsingDate ? (
                        <Spinner
                          className="loading-spinner"
                          color="#080ccb"
                        ></Spinner>
                      ) : (
                        <img
                          onClick={() =>
                            changePageFilterHandler(mealsFilter.page + 1)
                          }
                          src={
                            process.env.PUBLIC_URL + '/assets/down-arrow.svg'
                          }
                          alt="down-arrow"
                          className="pagination-down-arrow"
                        />
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="repeatList-div page-boundarie">
                <span className="repeatList-heading">
                  {translation('DINING_SCHEDULES.DINING_REPEAT_LISTS')}
                </span>
                <div className="repeatList-underline"></div>
                <div className="repeatList-accordion ">
                  {accordionNoData ? (
                    <span className="accordion-noData">
                      {translation('DINING_SCHEDULES.THERE_IS_NO_REPEATING')}
                    </span>
                  ) : (
                    ''
                  )}

                  <UncontrolledAccordion
                    flush
                    defaultOpen="false"
                    open={'0'}
                    className=""
                  >
                    {daysArray.map((day: string, index: number) => (
                      <DiningAccordion
                        key={index}
                        accKey={index}
                        showOptions={showOptions}
                        dropdown={dropdown}
                        restaurantImg={restaurant?.imageId}
                        day={day}
                        onCardClick={onCardClick}
                        addEditModalHandler={addEditModalHandler}
                        removeRepeatHandler={removeRepeatHandler}
                      />
                    ))}
                  </UncontrolledAccordion>
                </div>
              </div>
            </div>
            {openDiningRepeatModal && restaurant_id ? (
              <DiningModalWrap
                isOpen={openDiningRepeatModal}
                modalHeading={translation('RESTAURANT.ADD_REPEAT_LIST')}
              >
                <AddNewRepeat
                  currentDate={date}
                  restaurantId={restaurant_id}
                  isAdding={isAddingDiningMeal}
                  onCreate={addNewDiningMealsHandler}
                  restaurant={restaurant}
                />
              </DiningModalWrap>
            ) : null}
            {openDiningDailyAddEditModal && restaurant_id && dailyMealData ? (
              <DiningModalWrap
                isOpen={openDiningDailyAddEditModal}
                modalHeading={translation(`RESTAURANT.ADD/EDIT`)}
              >
                <AddEditDailyMeal
                  currentDate={date}
                  mealObject={mealObject}
                  diningItemsList={dailyMealData}
                  restaurantId={restaurant_id}
                  isAdding={isUpdatingDailyMealItems}
                  onCreate={updateDailyDiningMealsHandler}
                  restaurant={restaurant}
                />
              </DiningModalWrap>
            ) : null}

            {mealItems[0]?.mealId == modalMealData?.id && openMealDataModal ? (
              <DiningMealModal
                isOpen={openMealDataModal}
                modalHeadingData={modalMealData}
                mealItems={mealItems}
                restaurant={restaurant}
              />
            ) : null}
            {openDiningRepeatAddEditModal && restaurant_id && repeatMealData ? (
              <DiningModalWrap
                isOpen={openDiningRepeatAddEditModal}
                modalHeading="Add/Edit Repeat List"
              >
                <AddEditRepeatMeal
                  currentDate={date}
                  mealObject={mealObject}
                  diningItemsList={repeatMealData}
                  restaurantId={restaurant_id}
                  isAdding={isUpdatingRepeatMealItems}
                  onCreate={updateRepeatDiningMealsHandler}
                  restaurant={restaurant}
                />
              </DiningModalWrap>
            ) : null}
            {openDiningCategoryModal ? (
              <Modal
                isOpen={openDiningCategoryModal}
                fade={true}
                centered={true}
                size="md"
                className=""
              >
                <AddEditCategory onCreate={createNewCategory} />
              </Modal>
            ) : null}

            {openDiningCategoryListModal ? (
              <Modal
                isOpen={openDiningCategoryListModal}
                fade={true}
                centered={true}
                size="md"
                className=""
              >
                <ListCategory onCreate={onUpdateCategory} />
              </Modal>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default DiningPage;
