import * as yup from 'yup';

export const repeatFormSchema = yup
  .object({
    name: yup.string().required('ACTIVITIES_FORM.NAME_REQUIRED'),
    startTime: yup.string().required('ACTIVITIES_FORM.START_TIME_REQUIRED'),
    endTime: yup.string().required('ACTIVITIES_FORM.END_TIME_REQUIRED'),
  })
  .required();
export const categoryFormSchema = yup
  .object({
    category: yup.string().required('ACTIVITIES_FORM.CATEGORY_REQUIRED'),
  })
  .required();
