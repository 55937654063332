import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import 'swiper/css';
import { activeInactiveChecker } from '../../_helpers/checkerFunctions';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import ActivitiesForm from '../../components/activitiesForm/activitiesForm';
import ActivityCard from '../../components/activitiesForm/activityCard';
import { CalenderComp } from '../../components/calenderComp/calenderComp';
import { activityBreakPoints } from '../../components/calenderComp/swiperBreakPoints';
import { translateArrayList } from '../../components/cloudTranslationCommon/translation';
import DeleteConfirmation from '../../components/deleteConfirmation/deleteConfirmation';
import { ModalComponent } from '../../components/modal';
import {
  // eslint-disable-next-line prettier/prettier
  openAddModalReducer,
} from '../../components/modal/modalSlice';
import NoDataComponent from '../../components/noDataComponent/noDataComponent';
import {
  // eslint-disable-next-line prettier/prettier
  IPaginateClickData,
} from '../../components/paginate/paginate';
import SectionLoader from '../../components/sectionLoader/sectionLoader';
import '../diningPage/diningPage.css';
import { getSelectedFacility, translateText } from '../loginPage/authSlice';
import {
  activityFilterPageReset,
  addActivityTranslatedData,
  addFilterPage,
  addNewActivity,
  changeActivityOrder,
  deleteRepeatingActivity,
  deleteSelectedActivity,
  fetchActivityById,
  fetchActivityImageId_Add,
  fetchActivityImageId_Update,
  fetchAllActivities,
  resetActivityState,
  updateSelectedActivity,
} from './activiitiesSlice';
import { IActivity, IActivityCreateData } from './activitiesInterface';
import './activitiesPage.css';
import {
  addNewActivityPayload,
  deleteActivity,
  deleteActivityPayload,
  editActivity,
  filterChange,
  updateExistingActivityPayload,
} from './utilityFunctions';

const ActivitiesPage: React.FC = () => {
  const { f_id } = useParams();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const { openAddModal, openEditModal, openDeleteModal } = useAppSelector(
    (state) => state.modal
  );
  const [activeData, setActiveData] = useState<IActivity>({} as IActivity);
  const [items, setItems] = useState<IActivity[]>([]);
  const [activeId, setActiveId] = useState<any>(null);

  const {
    activity,
    isAddingAcitity,
    isAddingPoster,
    filter,
    activityList,
    isUpdatingActivity,
    isDeletingActivity,
    activityPaginate,
    isProcessingAllActivity,
    imageDetails,
  } = useAppSelector((state) => state.activities);
  const { currentFacility } = useAppSelector((state) => state.authentication);
  const [date, setDate] = useState(moment());
  const [mySwiper, setMyswiper] = useState<any>();
  const [editActivityId, setEditActivityId] = useState<string>('');
  const dispatch = useAppDispatch();
  const [translation] = useTranslation('common');
  const navigate = useNavigate();
  const [t, i18n] = useTranslation('common');

  const language = i18n.language;
  useEffect(() => {
    dispatch(activityFilterPageReset());
    if (f_id) {
      dispatch(getSelectedFacility(f_id));
    }
    return () => {
      dispatch(resetActivityState());
    };
  }, []);

  // translation
  useEffect(() => {
    if (language !== 'en' && activityList?.length > 0) {
      translateArrayList(
        activityList,
        language,
        dispatch,
        translateText,
        addActivityTranslatedData,
        ['name'] // Pass the properties that need to be translated
      );
    }
  }, [language, isProcessingAllActivity]);
  useEffect(() => {
    if (f_id) {
      language === 'en' &&
        dispatch(
          fetchAllActivities(
            filterChange(filter, f_id, date.format('YYYY-MM-DD'))
          )
        );
    }
  }, [language]);
  useEffect(() => {
    if (language !== 'en') {
      return () => {
        dispatch(addActivityTranslatedData([]));
      };
    }
  }, []);

  // -----------------------

  useEffect(() => {
    window.scrollTo(0, 0);
    if (f_id) {
      if (filter?.page === 0) {
        setItems([]);
      }
      dispatch(
        fetchAllActivities(
          filterChange(filter, f_id, date.format('YYYY-MM-DD'))
        )
      );
    }
  }, [filter, f_id, date]);

  const addActivityData = () => {
    dispatch(openAddModalReducer());
  };
  const addActivityModalHandler = (data: IActivityCreateData) => {
    if (f_id) {
      if (data?.image) {
        dispatch(
          fetchActivityImageId_Add(
            addNewActivityPayload(f_id, data, date.format('YYYY-MM-DD'))
          )
        );
      } else {
        dispatch(
          addNewActivity(
            addNewActivityPayload(f_id, data, date.format('YYYY-MM-DD'))
          )
        );
      }
    }
  };

  const editActivityData = (id: string) => {
    setEditActivityId(id);
    dispatch(fetchActivityById(editActivity(id)));
  };
  const editUserModalData = (data: IActivityCreateData) => {
    if (f_id) {
      if (data?.image) {
        dispatch(
          fetchActivityImageId_Update(
            updateExistingActivityPayload(
              activity?.id,
              f_id,
              data,
              date.format('YYYY-MM-DD')
            )
          )
        );
      } else {
        dispatch(
          updateSelectedActivity(
            updateExistingActivityPayload(
              activity?.id,
              f_id,
              data,
              date.format('YYYY-MM-DD')
            )
          )
        );
      }
    }
  };
  const deleteActivityData = (id: string) => {
    dispatch(fetchActivityById(deleteActivity(id)));
  };
  const deleteActivityModalHandler = (id: string, selectedOption: string) => {
    if (f_id) {
      const repeatable = activity?.repeatable;
      const formattedDate = date.format('YYYY-MM-DD');
      if (
        (activity?.weekdaysList?.length && repeatable == true) ||
        (activity?.particularDates?.length && repeatable == false)
      ) {
        dispatch(
          deleteRepeatingActivity({
            id,
            facilityId: f_id as string,
            date: formattedDate,
            repeatOption: selectedOption,
          })
        );
      } else {
        dispatch(
          deleteSelectedActivity(
            deleteActivityPayload(
              id,
              f_id as string,
              formattedDate,
              selectedOption
            )
          )
        );
      }
    }
  };

  const handlePageClick = (data: IPaginateClickData) => {
    if (data.selected !== activityPaginate.totalPages)
      dispatch(addFilterPage(data?.selected));
  };
  const changePageFilterHandler = (page: number) => {
    if (page + 1 <= activityPaginate.totalPages) {
      dispatch(addFilterPage(page));
    }
  };
  const eventDetailsNavigator = (item: IActivity) => {
    if (f_id && item?.id) {
      navigate(`/administration/activities/${f_id}/${item?.id}`);
    }
  };
  // useEffect(() => {
  //   setItems(activityList);
  // }, [activityList]);
  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setActiveId(event.active.id);
      const activeObj = items.filter(
        (obj: IActivity) => obj.id == event.active.id
      );

      setActiveData(activeObj[0]);
    },
    [items]
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = items.findIndex(
          (item: IActivity) => item.id == active?.id
        );

        const newIndex = items.findIndex(
          (item: IActivity) => item.id === over?.id
        );
        setItems((items: IActivity[]) => {
          return arrayMove(items, oldIndex, newIndex);
        });

        const newArray = arrayMove(items, oldIndex, newIndex).map(
          (items: IActivity, index: number) => ({
            activityId: items.id,
            position: index + 1,
          })
        );

        dispatch(
          changeActivityOrder({
            // date: date.format('YYYY-MM-DD'),
            newPosition: newArray,
          })
        );
      }

      setActiveId(null);
    },
    [items]
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);
  const makeArrayUnique = (inputArray: IActivity[]) => {
    const uniqueArray: IActivity[] = [];
    const idSet = new Set();

    inputArray.forEach((item) => {
      if (!idSet.has(item?.id)) {
        idSet.add(item?.id);
        uniqueArray.push(item);
      }
    });

    return uniqueArray;
  };
  const addNewRequestData = (activity: IActivity[]) => {
    const updatedRequestArray = [...items, ...activity];

    setItems(makeArrayUnique(updatedRequestArray));
  };
  useEffect(() => {
    if (activityList?.length === 0) {
      setItems([]);
    } else {
      addNewRequestData(activityList as IActivity[]);
    }
  }, [activityList]);

  return (
    <div className="page-background amenities-page">
      <div className="amenitiesPage-head">
        <h2>{translation('ACTIVITIES.TITLE')}</h2>
        <Button
          className="addAmenities-button shadow-none"
          disabled={!activeInactiveChecker(currentFacility?.status)}
          onClick={addActivityData}
        >
          {translation('ACTIVITIES_FORM.ADD_ACTIVITY')}
        </Button>
      </div>
      <div className="activity-calender-div">
        <CalenderComp
          setDate={setDate}
          setSwiperState={setMyswiper}
          dayBackFn={() => mySwiper.slidePrev()}
          dayForwardFn={() => mySwiper.slideNext()}
          swiperBreakPoints={activityBreakPoints}
        />
      </div>

      <span className="card-wrap-heading">
        {/* Today&#39;s Activities */}
        {translation('ACTIVITIES.TODAYS_ACTIVITY')}
      </span>

      {isProcessingAllActivity ? (
        // <PageLoader />
        <SectionLoader />
      ) : activityList?.length ? (
        <div className="activities-card-div activities-parent-card-R">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <SortableContext items={items} strategy={rectSortingStrategy}>
              {items.map((item: IActivity, index: number) => (
                <ActivityCard
                  key={index}
                  item={item}
                  currentDate={date}
                  editActivityData={editActivityData}
                  deleteActivityData={deleteActivityData}
                  eventDetailsNavigator={eventDetailsNavigator}
                />
              ))}
            </SortableContext>
            <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
              {activeId ? (
                <ActivityCard
                  key={0}
                  item={activeData}
                  currentDate={date}
                  editActivityData={editActivityData}
                  deleteActivityData={deleteActivityData}
                  eventDetailsNavigator={eventDetailsNavigator}
                />
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>
      ) : (
        <NoDataComponent constant="NO_ACTIVITY" />
      )}

      {openAddModal && (
        <ModalComponent
          isOpen={openAddModal}
          modalHeading={'ACTIVITIES_FORM.MODAL_TITLE_CREATE'}
        >
          <ActivitiesForm
            date={date}
            onCreate={addActivityModalHandler}
            isAdding={isAddingAcitity || isAddingPoster}
            buttonTitle={translation('BUTTONS.CREATE')}
          />
        </ModalComponent>
      )}
      {activity.id == editActivityId
        ? openEditModal && (
            <ModalComponent
              isOpen={openEditModal}
              // modalHeading={`Edit ${activity?.type?.charAt(0)}${activity?.type
              //   ?.slice(1)
              //   ?.toLowerCase()}`}
              modalHeading={'ACTIVITIES.EDIT_EVENT'}
            >
              <ActivitiesForm
                date={date}
                isEdit
                content={activity}
                onCreate={editUserModalData}
                isAdding={isUpdatingActivity || isAddingPoster}
                buttonTitle={translation('PROFILE_EDIT.UPDATE_BUTTON')}
                imageDetails={imageDetails}
                // isModalCloseTrgd={isModalCloseTrgd}
              />
            </ModalComponent>
          )
        : null}
      {activity
        ? openDeleteModal && (
            <ModalComponent
              isOpen={openDeleteModal}
              modalHeading={'ACTIVITIES_FORM.MODAL_TITLE_DELETE'}
            >
              <DeleteConfirmation
                repeatOption={true}
                content={activity}
                onDelete={
                  deleteActivityModalHandler as (
                    id: string,
                    selectedOption?: string
                  ) => void
                }
                isDeleting={isDeletingActivity}
              />
            </ModalComponent>
          )
        : null}
      {/* {activityPaginate?.totalPages > 1 && (
        <Paginate
          handlePageClick={handlePageClick}
          totalPage={activityPaginate?.totalPages}
        />
      )} */}
      {filter.page + 1 < activityPaginate?.totalPages && items?.length != 0 ? (
        <div className="pagination-down-arrow-container-activity">
          {isProcessingAllActivity ? (
            <Spinner className="loading-spinner" color="#080ccb"></Spinner>
          ) : (
            <img
              onClick={() => changePageFilterHandler(filter.page + 1)}
              src={process.env.PUBLIC_URL + '/assets/down-arrow.svg'}
              alt="down-arrow"
              className="pagination-down-arrow"
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ActivitiesPage;
