import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { useAppDispatch } from '../../app/hook';
import { IDeleteUser } from '../../pages/usersPage/usersInterface';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';

const DeleteConfirmation: React.FC<{
  content: IDeleteUser;
  onDelete: (id: string, selectedOption?: string) => void;
  isDeleting: boolean;
  repeatOption?: boolean;
}> = ({ content, onDelete, isDeleting, repeatOption }) => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation('common');
  const [selectedOption, setSelectedOption] = useState('REPEAT');

  const handleOptionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedOption(event.target.value);
  };
  const deleteHandler = (id: string) => {
    onDelete(id, selectedOption);
  };

  return (
    <div className="modal-div">
      <h5 className="delete-modal-message">
        {`${translation('DELETE_MODAL.MESSAGE')} ${
          content?.userName || content?.name || content?.restaurantName
        }`}
      </h5>
      <div className="activityModal-separator"></div>
      {/* {repeatOption && content.type !== 'NOTIFICATION' ? (
        <h6 className="delete-modal-message">
          <form className="activity-delete-radio-form">
            <div className="activity-delete-radio-div">
              <div className="activity-radio-btn">
                <input
                  className="pointer"
                  type="radio"
                  id="REPEAT"
                  name="repeatOption"
                  value="REPEAT"
                  checked={selectedOption === 'REPEAT'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="REPEAT" className="pointer">
                  {translation('DELETE_MODAL.REPEAT')}
                </label>
              </div>
              <div className="activity-radio-btn">
                <input
                  className="pointer"
                  type="radio"
                  id="DOES_NOT_REPEAT"
                  name="repeatOption"
                  value="DOES_NOT_REPEAT"
                  checked={selectedOption === 'DOES_NOT_REPEAT'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="DOES_NOT_REPEAT" className="pointer">
                  {translation('DELETE_MODAL.TODAY')}
                </label>
              </div>
            </div>
          </form>
          <div className="activityModal-separator"></div>
        </h6>
      ) : null} */}
      <div className="modal-submit-button-div">
        <Button
          type="submit"
          className="form-button shadow-none"
          onClick={() => deleteHandler(content?.id)}
        >
          {isDeleting ? <LoadingSpinner /> : translation('BUTTONS.DELETE')}
        </Button>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
