import React from 'react';
import { convert24hourTo12HourFormat } from '../../pages/activitiesPage/utilityFunctions';

const TimeComponent: React.FC<{
  startTime: string;
  endTime: string;
}> = ({ startTime, endTime }) => {
  return (
    <div className="restaurant-time">
      <span>{convert24hourTo12HourFormat(startTime)}</span>
      <span>&nbsp;-&nbsp;</span>
      <span>{convert24hourTo12HourFormat(endTime)}</span>
    </div>
  );
};

export default TimeComponent;
