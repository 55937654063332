/* eslint-disable prettier/prettier */
import { yupResolver } from '@hookform/resolvers/yup';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { useAppDispatch } from '../../app/hook';
import {
  ActivityType,
  IActivity,
  IActivityCreateData,
  IActivityRepeatTypes,
  IActivityValidation,
  activityRepeatTypes,
} from '../../pages/activitiesPage/activitiesInterface';
import {
  disablePastDate,
  useOnClickOutside,
} from '../../pages/activitiesPage/utilityFunctions';
import { IImageDetails } from '../../pages/amenitiesPage/amenitiesInterface';
import { IWeekdaysConstants } from '../../pages/diningPage/diningInterface';
import { WeedDays } from '../diningModal/modalConstants';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { activitiesSchema, activitiesSchemaTwo } from './activitiesValidation';

const ActivitiesForm: React.FC<{
  date: Moment;
  content?: IActivity;
  onCreate: (data: IActivityCreateData) => void;
  isAdding: boolean;
  buttonTitle: string;
  imageDetails?: IImageDetails;
  isEdit?: boolean;
  // isModalCloseTrgd?: boolean;
}> = ({
  content,
  onCreate,
  isAdding,
  buttonTitle,
  imageDetails,
  isEdit,
  date,
}) => {
  const [acitivityType, setActivityType] = useState<string>(
    ActivityType.notification
  );
  useEffect(() => {
    if (content?.type) {
      setActivityType(content?.type);
    }
  }, [content]);
  const [imageState, setImageState] = useState<any>('');
  const [imageName, setImageName] = useState<string>('');
  const [repeatType, setRepeatType] = useState<string>(
    content?.weekdaysList?.length
      ? 'repeat'
      : content?.particularDates?.length
      ? 'particularDays'
      : 'doesNotRepeat'
  );
  const [selectedOption, setSelectedOption] = useState('REPEAT');

  const handleOptionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedOption(event.target.value);
  };
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<IActivityValidation>({
    resolver: yupResolver(
      repeatType === 'repeat' || repeatType === 'particularDays'
        ? activitiesSchemaTwo
        : activitiesSchema
    ),
    defaultValues: {
      name: content?.name,
      startDate: content?.startingDate,
      startTime: content?.startingTime,
      endDate: content?.endingDate,
      endTime: content?.endingTime,
      location: content?.location,
      description: content?.description,
    },
  });
  const [translation] = useTranslation('common');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [startTime, setStartTime] = useState<string>('');

  const [multidateValues, setMultidateValues] = useState<string[]>(
    content?.particularDates ? content?.particularDates : []
  );
  const datePickerRef: any = useRef<HTMLInputElement>(null);
  const handleDatePickerClose = useCallback(
    () => datePickerRef.current.closeCalendar(),
    [datePickerRef]
  );
  useOnClickOutside(datePickerRef, handleDatePickerClose);
  const [weekdaysList, setWeekdaysList] = useState<string[]>(
    content?.weekdaysList ? content?.weekdaysList : []
  );

  useEffect(() => {
    content?.posterImageId && imageDetails
      ? setImageName(imageDetails?.originalName)
      : setImageName('');
  }, [content, imageDetails]);

  useEffect(() => {
    reset({
      name: content?.name,
      startDate: content?.startingDate,
      startTime: content?.startingTime,
      endDate: content?.endingDate,
      endTime: content?.endingTime,
      location: content?.location,
      description: content?.description,
    });
    if (content?.weekdaysList?.length) {
      setWeekdaysList(content?.weekdaysList);
      setMultidateValues([]);
      setRepeatType('repeat');
    }
    if (content?.particularDates?.length) {
      setMultidateValues(content?.particularDates);
      setWeekdaysList([]);
      setRepeatType('particularDays');
    }
    if (content?.startingDate && content?.endingDate) {
      setRepeatType('doesNotRepeat');
    }
  }, [content]);
  useEffect(() => {
    if (selectedOption === 'DOES_NOT_REPEAT') {
      if (content?.weekdaysList?.length) {
        setWeekdaysList(content?.weekdaysList);
        setMultidateValues([]);
        setRepeatType('repeat');
      }
      if (content?.particularDates?.length) {
        setMultidateValues(content?.particularDates);
        setWeekdaysList([]);
        setRepeatType('particularDays');
      }
      if (content?.startingDate && content?.endingDate) {
        setRepeatType('doesNotRepeat');
        reset({
          startDate: content?.startingDate,
          endDate: content?.endingDate,
          location: content?.location,
          description: content?.description,
        });
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    setEndDate(watch('endDate'));
    setStartDate(watch('startDate'));
    watch('startTime');
    setStartTime(watch('startTime'));
  }, [watch('startDate'), watch('startTime'), watch('endDate')]);

  const itemActivityTypeSetter = (type: string) => {
    setActivityType(type);
  };
  const uploadPosterHandler = (idName: string) => {
    document.getElementById(idName)!.click();
  };
  const posterOnChange = (e: any) => {
    const fileObj = e.target.files;
    setImageState(Array.from(fileObj));
    setImageName(fileObj[0].name);
  };

  const isWeedDayPresent = (val: string) => {
    return weekdaysList.some((item: string) => val === item);
  };

  const handleCheckboxChange = (value: string) => {
    let data: string[] = weekdaysList;
    if (isWeedDayPresent(value)) {
      data = weekdaysList?.filter((item: string) => item !== value);
    } else {
      data = [...weekdaysList, value];
    }
    setWeekdaysList(data);
  };

  const onSubmit = (data: IActivityValidation) => {
    let payload: IActivityCreateData = {} as IActivityCreateData;
    if (acitivityType == ActivityType.event) {
      if (repeatType === 'doesNotRepeat') {
        payload = {
          name: data?.name,
          startingDate: data?.startDate,
          startingTime: data?.startTime,
          endingDate: data?.endDate,
          endingTime: data?.endTime,
          repeatable: false,
          description: data?.description ? data?.description : '',
          location: data?.location ? data?.location : '',
          image: imageState,
          type: acitivityType,
          weekdaysList: [],
          particularDates: [],
          repeatOption: selectedOption,
          updateForSpecifiedDate: date.format('YYYY-MM-DD'),
          posterImageId: content?.posterImageId,
        };
      } else if (repeatType === 'repeat') {
        if (weekdaysList.length == 0) {
          toast.warning('Please select Repeating Days');
          return;
        }
        payload = {
          name: data?.name,
          startingDate: '',
          startingTime: data?.startTime,
          endingDate: '',
          endingTime: data?.endTime,
          location: data?.location ? data?.location : '',
          description: data?.description ? data?.description : '',
          image: imageState,
          type: acitivityType,
          repeatable: true,
          weekdaysList: weekdaysList,
          particularDates: [],
          repeatOption: selectedOption,
          updateForSpecifiedDate: date.format('YYYY-MM-DD'),
          posterImageId: content?.posterImageId,
        };
      } else if (repeatType === 'particularDays') {
        if (multidateValues.length == 0) {
          toast.warning('Please select Particular Days');
          return;
        }
        const datesArray = multidateValues?.map((dates: string) =>
          moment(dates).format('YYYY-MM-DD')
        );
        if (datesArray.length) {
          payload = {
            name: data?.name,
            startingDate: '',
            startingTime: data?.startTime,
            endingDate: '',
            endingTime: data?.endTime,
            type: acitivityType,
            description: data?.description ? data?.description : '',
            location: data?.location ? data?.location : '',
            image: imageState,
            repeatable: false,
            particularDates: datesArray,
            weekdaysList: [],
            repeatOption: selectedOption,
            updateForSpecifiedDate: date.format('YYYY-MM-DD'),
            posterImageId: content?.posterImageId,
          };
        }
      }
    } else if (acitivityType == ActivityType.notification) {
      payload = {
        name: data?.name,
        startingDate: data?.startDate,
        startingTime: data?.startTime,
        endingDate: data?.endDate,
        endingTime: data?.endTime,
        type: acitivityType,
        repeatOption: selectedOption,
        updateForSpecifiedDate: date.format('YYYY-MM-DD'),
      };
    }

    onCreate(payload);
  };

  const getParticularDates = (dateObject: string) => {
    const str: string[] = JSON.parse(dateObject);
    setMultidateValues((preV) => [...str]);
  };
  const getDisabled = () => {
    // Check if in edit mode and activity type is event
    if (isEdit && acitivityType === ActivityType.event) {
      if (
        repeatType === 'doesNotRepeat' &&
        selectedOption === 'DOES_NOT_REPEAT'
      ) {
        // Return false if repeat type is 'doesNotRepeat'
        return true;
      } else {
        if (selectedOption === 'DOES_NOT_REPEAT') {
          // Return true if selected option is 'DOES_NOT_REPEAT'
          return true;
        } else {
          // Return false if selected option is not 'DOES_NOT_REPEAT'
          return false;
        }
      }
    } else {
      // Return false if not in edit mode or activity type is not event
      return false;
    }
  };
  return (
    <div className="modal-div">
      <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
        {content ? null : (
          <div className="item-type-buttonGroup activityForm">
            <div
              className={
                acitivityType == ActivityType.notification
                  ? 'exclusiveItem-button'
                  : 'normalItem-button'
              }
              onClick={() => itemActivityTypeSetter(ActivityType.notification)}
            >
              {translation(`ACTIVITIES.NOTIFICATION`)}
            </div>
            <div
              className={
                acitivityType == ActivityType.event
                  ? 'exclusiveItem-button'
                  : 'normalItem-button'
              }
              onClick={() => itemActivityTypeSetter(ActivityType.event)}
            >
              {translation(`ACTIVITIES.EVENT`)}
            </div>
          </div>
        )}

        <FormGroup>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input
                placeholder={translation(`ACTIVITIES.TITLE_PLACEHOLDER`)}
                className="form-input shadow-none"
                invalid={errors?.name ? true : false}
                {...field}
              />
            )}
          />
          <FormFeedback>{translation(`${errors?.name?.message}`)}</FormFeedback>
        </FormGroup>

        {acitivityType == ActivityType.event && (
          <FormGroup>
            <Input
              className="form-input shadow-none repeatType-dropdown"
              name="select"
              type="select"
              disabled={getDisabled()}
              value={repeatType}
              onChange={(e) => setRepeatType(e.target.value)}
            >
              {activityRepeatTypes.map((type: IActivityRepeatTypes) => (
                <option key={type.type} value={type?.type}>
                  {type?.name}
                </option>
              ))}
            </Input>
          </FormGroup>
        )}

        {(repeatType === 'doesNotRepeat' &&
          acitivityType == ActivityType.event) ||
        acitivityType == ActivityType.notification ? (
          <div className="date-time-parent-div">
            <FormGroup className="date-time-div">
              <Label className="input-label center-activity-label">
                {translation(`ACTIVITIES.START_DATE`)}
              </Label>

              <Controller
                control={control}
                name="startDate"
                render={({ field }) => (
                  <Input
                    type="date"
                    disabled={getDisabled()}
                    min={
                      buttonTitle === translation('BUTTONS.CREATE')
                        ? disablePastDate()
                        : ''
                    }
                    // placeholder="--/--/----"
                    className={
                      field.value || content?.startingDate
                        ? 'date-input hasValue shadow-none'
                        : 'date-input placeholderClass shadow-none'
                    }
                    invalid={errors?.startDate ? true : false}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {translation(`${errors?.startDate?.message}`)}
              </FormFeedback>
            </FormGroup>
            <FormGroup className="date-time-div">
              <Label className="input-label center-activity-label">
                {translation(`ACTIVITIES.END_DATE`)}
              </Label>
              <Controller
                control={control}
                name="endDate"
                render={({ field }) => (
                  <Input
                    type="date"
                    min={startDate}
                    disabled={getDisabled()}
                    // placeholder="--/--/----"
                    className={
                      field.value || content?.endingDate
                        ? 'date-input hasValue shadow-none'
                        : 'date-input placeholderClass shadow-none'
                    }
                    invalid={errors?.endDate ? true : false}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {translation(`${errors?.endDate?.message}`)}
              </FormFeedback>
            </FormGroup>
          </div>
        ) : null}
        {repeatType === 'repeat' && acitivityType == ActivityType.event && (
          <div className="weekday-checkbox-div repeatDays-checkbox">
            {WeedDays.map((item: IWeekdaysConstants, index: number) => (
              <label
                key={index}
                htmlFor={item.value}
                className="weekday-checkbox"
              >
                <input
                  disabled={getDisabled()}
                  type="checkbox"
                  id={item.value}
                  checked={
                    item.value ==
                    weekdaysList
                      .filter((day: string) => item.value == day)
                      .toString()
                  }
                  className="pointer"
                  onChange={() => handleCheckboxChange(item.value)}
                />
                {item.content}
              </label>
            ))}
          </div>
        )}
        {repeatType === 'particularDays' &&
          acitivityType == ActivityType.event && (
            <DatePicker
              disabled={getDisabled()}
              ref={datePickerRef}
              multiple
              value={multidateValues}
              className="datePicker-container"
              onChange={(dateObject: DateObject) =>
                getParticularDates(JSON.stringify(dateObject))
              }
              render={
                <InputIcon
                  placeholder="Select Days"
                  className="datePicker-input"
                />
              }
              plugins={[<DatePanel key={multidateValues?.length} />]}
              format="DD MMM YYYY"
              minDate={new Date()}
            >
              <div className="multiselect-calender-action-btn-div">
                <button
                  className="button"
                  onClick={() => datePickerRef.current.closeCalendar()}
                >
                  Done
                </button>
              </div>
            </DatePicker>
          )}
        <div className="date-time-parent-div">
          <FormGroup className="date-time-div">
            <Label className="input-label center-activity-label">
              {translation(`ACTIVITIES.START_TME`)}
            </Label>
            <Controller
              control={control}
              name="startTime"
              render={({ field }) => (
                <Input
                  type="time"
                  placeholder="--:--"
                  className="dining-date-input shadow-none pointer"
                  invalid={errors?.startTime ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback>
              {translation(`${errors?.startTime?.message}`)}
            </FormFeedback>
          </FormGroup>
          <FormGroup className="date-time-div">
            <Label className="input-label center-activity-label">
              {translation(`ACTIVITIES.END_TIME`)}
            </Label>
            <Controller
              control={control}
              name="endTime"
              render={({ field }) => (
                <Input
                  type="time"
                  // min={startDate == endDate ? minTime() : ''}
                  // max="18:21:00"
                  // placeholder={translation(
                  //   'ACTIVITIES_FORM.ENDTIME_PLACEHOLDER'
                  // )}
                  placeholder="--:--"
                  className="dining-date-input shadow-none pointer"
                  invalid={errors?.endTime ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback>
              {translation(`${errors?.endTime?.message}`)}
            </FormFeedback>
          </FormGroup>
        </div>
        {acitivityType == ActivityType.event && (
          <>
            <FormGroup>
              <Controller
                control={control}
                name="location"
                render={({ field }) => (
                  <Input
                    placeholder={translation('ACTIVITIES.LOCATION')}
                    className="form-input shadow-none"
                    invalid={errors?.location ? true : false}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {translation(`${errors?.location?.message}`)}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Controller
                control={control}
                name="description"
                render={({ field }) => (
                  <Input
                    type="textarea"
                    placeholder={translation('ACTIVITIES.DESCRIPTION')}
                    row="10"
                    className="textarea-input shadow-none"
                    invalid={errors?.description ? true : false}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {translation(`${errors?.description?.message}`)}
              </FormFeedback>
            </FormGroup>
            <div
              className="users-imageUpload-div pointer"
              onClick={() => uploadPosterHandler('amenity_image')}
            >
              {imageName ? (
                <span className="uploadImage-input">
                  {imageName && translation(`${imageName}`)}
                </span>
              ) : (
                <span className="uploadImage-text">
                  {translation('ACTIVITIES.UPLOAD_EVENT_POSTER')}
                </span>
              )}
              <div className="">
                <img
                  src={process.env.PUBLIC_URL + '/assets/uploadIcon-dark.svg'}
                  alt="upload-icon"
                />
              </div>
              <input
                id="amenity_image"
                type="file"
                style={{ display: 'none' }}
                accept="image/jpeg, image/png"
                onChange={(e) => posterOnChange(e)}
              />
            </div>
          </>
        )}
        <div className="activityModal-separator"></div>
        {/* {isEdit && acitivityType === ActivityType.event ? (
          <h6 className="delete-modal-message">
            <form>
              <div className="activity-radio-btn-edit">
                <input
                  className="pointer"
                  type="radio"
                  id="REPEAT"
                  name="repeatOption"
                  value="REPEAT"
                  checked={selectedOption === 'REPEAT'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="REPEAT" className="pointer">
                  {translation('DELETE_MODAL.EDIT_REPEAT')}
                </label>
              </div>
              <div className="activity-radio-btn-edit">
                <input
                  className="pointer"
                  type="radio"
                  id="DOES_NOT_REPEAT"
                  name="repeatOption"
                  value="DOES_NOT_REPEAT"
                  checked={selectedOption === 'DOES_NOT_REPEAT'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="DOES_NOT_REPEAT" className="pointer">
                  {translation('DELETE_MODAL.EDIT_TODAY')}
                </label>
              </div>
            </form>
            <label className=" mb-10">
              {translation('DELETE_MODAL.EDIT_PROMPT')}
            </label>
            <div className="activityModal-separator"></div>
          </h6>
        ) : null} */}
        <div className="modal-submit-button-div">
          <Button type="submit" className="form-button shadow-none">
            {isAdding ? (
              <LoadingSpinner />
            ) : (
              translation(`AMENITIES_FORM.SAVE_UPDATE`)
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ActivitiesForm;
